<template>
  <div
    class="matchList-content_overlay"
    :class="{
      'match-detail__header-bottom__section': hasLive,
    }"
  >
    <div class="matchList-content_wrapper">
      <template v-if="results.matchList">
        <table
          class="matchList-content_table table-full"
          v-for="(res, i) in theResults.slice(0, lengthSelected)"
          :key="res.matchId"
        >
          <thead>
            <tr>
              <th
                class="matchList-content_table-title"
                :colspan="subLabel.length"
              >
                {{ res.homeTeamName + " VS " + res.awayTeamName }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="results.matchList">
              <tr>
                <td class="matchList-content_separator" :colspan="2">
                  {{ $t(label[0].name) }}
                </td>
                <td :colspan="4">{{ theResults[i].leagueName }}</td>
              </tr>
              <tr>
                <td class="matchList-content_separator" :colspan="2">
                  {{ $t(label[1].name) }}
                </td>
                <td :colspan="4">
                  {{ matchDate(theResults[i].matchTime) }}
                </td>
              </tr>
              <tr>
                <td class="matchList-content_separator" :colspan="2">
                  {{ $t(label[2].name) }}
                </td>
                <td :colspan="4">{{ score[i] }}</td>
              </tr>
              <tr>
                <td class="matchList-content_separator" :colspan="2">
                  {{ $t(label[3].name) }}
                </td>
                <td :colspan="4">{{ corner[i] }}</td>
              </tr>
              <tr>
                <td class="matchList-content_separator" :colspan="3">
                  {{
                    (thisOdds
                      ? $t(thisOdds.handicapOptions.company.name)
                      : $t("NO_DATA")) +
                      "\xa0\xa0\xa0" +
                      (thisOdds
                        ? $t(thisOdds.handicapOptions.duration.name)
                        : $t("NO_DATA"))
                  }}
                </td>
                <td class="matchList-content_separator" :colspan="3">
                  {{
                    (thisOdds
                      ? $t(thisOdds.oddsOptions.company.name)
                      : $t("NO_DATA")) +
                      "\xa0\xa0\xa0" +
                      (thisOdds
                        ? $t(thisOdds.oddsOptions.duration.name)
                        : $t("NO_DATA"))
                  }}
                </td>
              </tr>
              <tr>
                <td v-for="sl in subLabel.slice(0, 6)" :key="sl">
                  {{ $t(sl) }}
                </td>
              </tr>
              <tr>
                <template v-if="handicapOptions.result">
                  <td>
                    {{
                      nullCheck(
                        handicapOptions.result.flat()[i][
                          thisOdds.handicapOptions.duration.state +
                            thisOdds.matchOptions.state +
                            "Odds2"
                        ]
                      )
                    }}
                  </td>
                  <td>
                    {{
                      nullCheck(
                        handicapOptions.result.flat()[i][
                          thisOdds.handicapOptions.duration.state +
                            thisOdds.matchOptions.state +
                            "Odds1"
                        ]
                      )
                    }}
                  </td>

                  <td>
                    {{
                      nullCheck(
                        handicapOptions.result.flat()[i][
                          thisOdds.handicapOptions.duration.state +
                            thisOdds.matchOptions.state +
                            "Odds3"
                        ]
                      )
                    }}
                  </td>
                </template>
                <template v-if="oddsOptions.result">
                  <td>
                    {{
                      nullCheck(
                        oddsOptions.result.flat()[i][
                          thisOdds.oddsOptions.duration.state +
                            thisOdds.matchOptions.state +
                            "Odds1"
                        ]
                      )
                    }}
                  </td>
                  <td>
                    {{
                      nullCheck(
                        oddsOptions.result.flat()[i][
                          thisOdds.oddsOptions.duration.state +
                            thisOdds.matchOptions.state +
                            "Odds2"
                        ]
                      )
                    }}
                  </td>
                  <td>
                    {{
                      nullCheck(
                        oddsOptions.result.flat()[i][
                          thisOdds.oddsOptions.duration.state +
                            thisOdds.matchOptions.state +
                            "Odds3"
                        ]
                      )
                    }}
                  </td>
                </template>
              </tr>
              <tr>
                <td
                  :colspan="subLabel.length"
                  class="matchList-content_separator"
                >
                  {{
                    thisOdds ? $t(thisOdds.matchOptions.name) : $t("NO_DATA")
                  }}
                </td>
              </tr>
              <tr>
                <td
                  :colspan="2"
                  v-for="sl in subLabel.slice(6, subLabel.length)"
                  :key="sl"
                  class="matchList-content_separator"
                >
                  {{ $t(sl) }}
                </td>
              </tr>
              <tr>
                <template v-if="handicapOptions.result">
                  <td :colspan="2">
                    {{
                      matchResult(
                        handicapOptions.result.flat()[i][
                          thisOdds.matchOptions.state.toLowerCase() + "WinFlag"
                        ]
                      )
                    }}
                  </td>
                  <td :colspan="2">
                    {{
                      winLose(
                        handicapOptions.result.flat()[i][
                          thisOdds.handicapOptions.duration.state +
                            thisOdds.matchOptions.state +
                            "ConcedeFlag"
                        ]
                      )
                    }}
                  </td>
                  <td :colspan="2">
                    {{
                      bigSmall(
                        handicapOptions.result.flat()[i][
                          thisOdds.handicapOptions.duration.state +
                            thisOdds.matchOptions.state +
                            "GoalFlag"
                        ]
                      )
                    }}
                  </td>
                </template>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="no-stat" :colspan="label.length + subLabel.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="subLabel.length"></td>
            </tr>
          </tfoot>
        </table>
      </template>
    </div>
  </div>

  <Transition name="slide-in">
    <keep-alive>
      <router-view
        v-if="isMobile"
        :thisOdds="thisOdds"
        :listOption="list"
        :lengthSelected="lengthSelected"
        @selectList="listLengthHandler"
        :oddsOptions="oddsOptions"
        :handicapOptions="handicapOptions"
        @selectCompany="companySelectHandler"
        :matchDuration="matchDuration"
        :matchPeriod="matchPeriod"
        :matchOptions="matchOptions"
        @selectDuration="durationSelectHandler"
        :leagueOption="results.leagueList"
        @selectLeague="leagueListHandler"
        @selectSide="isSameHandler"
      />
    </keep-alive>
  </Transition>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isNoData: false,
      isLoading: false,
      oddsMore: false,
      listOption: [10, 20, 30],
      lengthSelected: null,
      label: [
        { name: "EVENT", colspan: 0, rowspan: 2 },
        { name: "DATE", colspan: 0, rowspan: 2 },
        { name: "SCORE_HALFTIME", colspan: 0, rowspan: 2 },
        { name: "CORNER_KICK", colspan: 0, rowspan: 2 },
      ],
      subLabel: [
        "HOME",
        "HANDICAP",
        "AWAY",
        "HOME",
        "DRAW",
        "AWAY",
        "RESULTS",
        "HANDICAP",
        "B/S",
      ],
      company: [
        { name: "IBCbet", id: 1001 },
        { name: "SBOBET", id: 31 },
        { name: "SINGBET", id: 3 },
        // { name: "MACAUSLOT", id: 1 },
        { name: "LADBROKES", id: 4 },
        { name: "SNAI", id: 7 },
        { name: "BET365", id: 8 },
        { name: "WILLIAMHILL", id: 9 },
        { name: "YSB", id: 12 },
        { name: "BETVICTOR", id: 14 },
        { name: "MANSION88", id: 17 },
        { name: "INTERWETTEN", id: 19 },
        { name: "10BET", id: 22 },
        { name: "188BET", id: 23 },
        { name: "12BET", id: 24 },
        { name: "WANBET", id: 35 },
        { name: "18BET", id: 42 },
        { name: "MANBETX", id: 45 },
        { name: "PINNACLE", id: 47 },
      ],
      matchDuration: [
        { state: "last", name: "FT" },
        { state: "first", name: "HT" },
      ],
      matchPeriod: [
        { state: "Full", name: "FULL_TIME" },
        { state: "Half", name: "HALF_TIME" },
      ],
      oddsOptions: {
        companyList: "",
        company: "",
        duration: "",
        companyShow: false,
        durationShow: false,
        result: "",
      },
      handicapOptions: {
        companyList: "",
        company: "",
        duration: "",
        companyShow: false,
        durationShow: false,
        result: "",
      },
      matchOptions: {
        matchShow: false,
        duration: "",
      },
      leagueList: [],
      filteredResults: "",
      isSame: false,
      results: "",
      matchId: "",
      side: "",
      thisOdds: "",
    };
  },
  created() {
    this.side = this.$route.params.side;
    this.matchId = this.$route.params.matchId;
  },
  mounted() {
    this.init();
    document.body.className = "hidden";
  },
  beforeUnmount() {
    document.body.className = "unset";
    this.isOddsMoreToggle();
    this.isOddsMenuToggle();
  },
  computed: {
    ...mapGetters([
      "isMobile",
      "isOddsMore",
      "homeOdds",
      "awayOdds",
      "h2hOdds",
      "hasLive",
      "currentCountryObj"
    ]),
    score() {
      return this.theResults.map(
        (m) =>
          (m.homeScore ? m.homeScore : "0") +
          "-" +
          (m.awayScore ? m.awayScore : "0") +
          " (" +
          (m.homeHalfScore ? m.homeHalfScore : "0") +
          "-" +
          (m.awayHalfScore ? m.awayHalfScore : "0") +
          ")"
      );
    },
    corner() {
      return this.theResults.map(
        (m) =>
          (m.homeCorner ? m.homeCorner : "0") +
          "-" +
          (m.awayCorner ? m.awayCorner : "0")
      );
    },
    theResults() {
      if (this.filteredResults.length !== 0) {
        return this.filteredResults;
      } else return this.results.matchList;
    },
    list() {
      for (let i = 0; i < this.listOption.length; i++) {
        if (
          this.results &&
          this.results.matchList.length > this.listOption[i]
        ) {
          return this.listOption.slice(0, i - 1);
        }
      }
    },
  },
  methods: {
    ...mapActions([
      "getLeagueOdds",
      "isOddsMoreToggle",
      "isOddsMenuToggle",
      "oddsOptionChangeEvent",
      "isSameToggle",
      "oddsLeagueListHandler",
    ]),
    init() {
      this.getLeagueOddsData();
    },
    // Initialization
    async getLeagueOddsData() {
      this.isLoading = true;

      const result = await this.getLeagueOdds({
        side: this.side,
        matchId: { matchId: this.matchId },
      });
      this.isLoading = false;

      this.dataInit(result.result);
    },
    dataInit(data) {
      if (!data) {
        this.isNoData = true;
        return;
      } else {
        this.isNoData = false;
        this.results = data;
        // this.mobileResults = this.results[this.teamSelected];
      }

      if (this.side === "home") {
        this.thisOdds = this.homeOdds;
      } else if (this.side === "away") {
        this.thisOdds = this.awayOdds;
      } else this.thisOdds = this.h2hOdds;

      // matchList Length to show
      this.listLengthHandler(this.listOption[0]);
      // oddsInit

      for (let i = 0; i <this.company.length; i++) {
        if (this.company[i].id=== 1001) {
          this.company[i].name = this.currentCountryObj?.countryCode === "TH" ? "SBOBET" : "IBCbet"
        }
      }
      this.oddsOptions.companyList = this.company.filter((o1) =>
        this.results.matchList[0].europeOddsList.some(
          (o2) => o1.id === o2.companyId
        )
      );
      if (!this.thisOdds.matchOptions.name) {
        this.oddsOptionChangeEvent({
          side: this.side,
          table: "oddsOptions",
          attr: "company",
          data: this.oddsOptions.companyList[0],
        });
        this.oddsOptionChangeEvent({
          side: this.side,
          table: "oddsOptions",
          attr: "duration",
          data: this.matchDuration[0],
        });
      }

      // this.oddsOptions.company = this.oddsOptions.companyList[0];
      // this.oddsOptions.duration = this.matchDuration[0];
      this.oddsResultFilter(this.thisOdds.oddsOptions.company.id);

      // handicapInit
      this.handicapOptions.companyList = this.company.filter((o1) =>
        this.results.matchList[0].handicapList.some(
          (o2) => o1.id === o2.companyId
        )
      );
      if (!this.thisOdds.matchOptions.name) {
        this.oddsOptionChangeEvent({
          side: this.side,
          table: "handicapOptions",
          attr: "company",
          data: this.handicapOptions.companyList[0],
        });
        this.oddsOptionChangeEvent({
          side: this.side,
          table: "handicapOptions",
          attr: "duration",
          data: this.matchDuration[0],
        });
      }

      // this.handicapOptions.company = this.handicapOptions.companyList[0];
      // this.handicapOptions.duration = this.matchDuration[0];
      this.handicapResultFilter(this.thisOdds.handicapOptions.company.id);

      // matchInit
      if (!this.thisOdds.matchOptions.name) {
        this.oddsOptionChangeEvent({
          side: this.side,
          table: "matchOptions",
          data: this.matchPeriod[0],
        });
      }

      this.filterHandler();
    },
    // Handler
    dropdownHandler(data, table) {
      this[table][data] = !this[table][data];
    },
    companySelectHandler(data, table) {
      this.oddsOptionChangeEvent({
        side: this.side,
        table: table,
        attr: "company",
        data: data,
      });

      // this[table].company = data;
      if (table === "oddsOptions") {
        this.oddsResultFilter(data.id);
      } else if (table === "handicapOptions") {
        this.handicapResultFilter(data.id);
      }
    },
    durationSelectHandler(data, table) {
      if (table === "matchOptions") {
        this.oddsOptionChangeEvent({
          side: this.side,
          table: table,
          attr: null,
          data: data,
        });
      } else {
        this.oddsOptionChangeEvent({
          side: this.side,
          table: "oddsOptions",
          attr: "duration",
          data: data,
        });
        this.oddsOptionChangeEvent({
          side: this.side,
          table: "handicapOptions",
          attr: "duration",
          data: data,
        });
      }
    },
    oddsResultFilter(data) {
      this.oddsOptions.result = this.theResults.map((m) => {
        return m.europeOddsList.filter((odd) => odd.companyId === data);
      });
    },
    handicapResultFilter(data) {
      this.handicapOptions.result = this.theResults.map((m) => {
        return m.handicapList.filter((handicap) => handicap.companyId === data);
      });
    },
    matchDate(date) {
      return moment(date).format("YY-MM-DD");
    },
    listLengthHandler(data) {
      this.lengthSelected = data;
    },
    leagueListHandler(data) {
      this.oddsLeagueListHandler({ side: this.side, data: data });

      this.filterHandler();
    },
    isSameHandler() {
      this.isSameToggle(this.side);
      this.filterHandler();
    },
    filterHandler() {
      if (this.thisOdds.leagueList.length !== 0) {
        if (this.thisOdds.isSameLeague) {
          this.filteredResults = this.results.matchList.filter((o1) =>
            this.thisOdds.leagueList.some(
              (o2) => o1.leagueId === o2 && o1.isSame
            )
          );
        } else
          this.filteredResults = this.results.matchList.filter((o1) =>
            this.thisOdds.leagueList.some((o2) => o1.leagueId === o2)
          );
      } else {
        if (this.thisOdds.isSameLeague) {
          this.filteredResults = this.results.matchList.filter(
            (o1) => o1.isSame
          );
        } else this.filteredResults = [];
      }

      this.oddsResultFilter(this.thisOdds.oddsOptions.company.id);
      this.handicapResultFilter(this.thisOdds.handicapOptions.company.id);
    },
    // Displaying Value if not null
    nullCheck(data) {
      return data ? data : "-";
    },
    winLose(data) {
      switch (data) {
        case -1:
          return this.$t("L");
        case 0:
          return this.$t("DRAW");
        case 1:
          return this.$t("W");
        default:
          return "-";
      }
    },
    bigSmall(data) {
      switch (data) {
        case -1:
          return this.$t("SMALL");
        case 0:
          return this.$t("DRAW");
        case 1:
          return this.$t("BIG");
        default:
          return "-";
      }
    },
    matchResult(data) {
      switch (data) {
        case -1:
          return this.$t("L");
        case 0:
          return this.$t("DRAW");
        case 1:
          return this.$t("W");
        default:
          return "-";
      }
    },
  },
};
</script>

<style scoped>
.matchList-content_overlay {
  position: fixed;
  overflow-y: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  z-index: 2;
  /* display: flex; */
  /* align-items: center; */
}
.matchList-content_wrapper {
  margin: 3rem auto 5rem auto;
  width: 95%;
  flex-direction: column;
}
.matchList-content_table {
  margin-top: 1rem;
}
.matchList-content_table td {
  padding: 0.375rem 0;
}

.slide-in-leave-active,
.slide-in-enter-active {
  transition: all 0.3s ease-in;
}
.slide-in-enter-from,
.slide-in-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(500px);
}
</style>
